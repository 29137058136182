import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Getting started`}</h2>
    <p>{`Control the features of the IBM Video Streaming player and customize its layout, while maintaining a continuous, interactive data connection with IBM Video Streaming’s backend. Embed the player anywhere and manage it according to your needs and preferences.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      